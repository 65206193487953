import initialState from './initialState'
import _ from 'lodash'

const defaultTrip = {
  pickup: '',
  pickupIsland: '',
  return: '',
  returnIsland: '',
  from: '',
  until: '',
  promo: '',
  discount: '',
  autoClubActive: false,
  autoclubmembershiptype: '',
  autoclubmembership: '',
  blueChipMembershipActive: false,
  bluechipname: '',
  promoCampaign: '',
  bluechipnumber: '',
  searchType: 'car',
  velocityabn: '',
  cdp: '',
  dob: '',
  res: '',
  status: '',
  paid: false,
  recorded: false,
  conf: '',
  car: '',
  initCar: '',
  rid: '',
  cover: '',
  notes: '',
  afterHoursPickup: false,
  extras: [],
  rates: {},
  options: [],
  estimate: {},
  payment: {},
}

export default function modelsReducer(state = initialState.models, action) {
    switch (action.type) {
        case 'getCMSLocations.ok':
            return {
                ...state,
                locations: {
                    ...action.response,
                },
            }
        case 'getCMSVehicles.ok':
            return {
                ...state,
                vehicles: {
                    au: _.transform(
                        [...action.response].filter(vehicle => vehicle.vehicle_location == 'AU'),
                        (result, value) => { result[value.class] = value },{}
                    ),
                    nz: _.transform(
                        [...action.response].filter(vehicle => vehicle.vehicle_location == 'NZ'),
                        (result, value) => { result[value.class] = value },{}
                    ),
                    bg: _.transform(
                        [...action.response].filter(vehicle => vehicle.vehicle_location == 'BG'),
                        (result, value) => { result[value.class] = value },{}
                    ),
                    ca: _.transform(
                        [...action.response].filter(vehicle => vehicle.vehicle_location == 'CA'),
                        (result, value) => { result[value.class] = value },{}
                    ),
                    de: _.transform(
                        [...action.response].filter(vehicle => vehicle.vehicle_location == 'DE'),
                        (result, value) => { result[value.class] = value },{}
                    ),
                    fi: _.transform(
                        [...action.response].filter(vehicle => vehicle.vehicle_location == 'FI'),
                        (result, value) => { result[value.class] = value },{}
                    ),
                    fr: _.transform(
                        [...action.response].filter(vehicle => vehicle.vehicle_location == 'FR'),
                        (result, value) => { result[value.class] = value },{}
                    ),
                    gb: _.transform(
                        [...action.response].filter(vehicle => vehicle.vehicle_location == 'GB'),
                        (result, value) => { result[value.class] = value },{}
                    ),
                    gr: _.transform(
                        [...action.response].filter(vehicle => vehicle.vehicle_location == 'GR'),
                        (result, value) => { result[value.class] = value },{}
                    ),
                    hr: _.transform(
                        [...action.response].filter(vehicle => vehicle.vehicle_location == 'HR'),
                        (result, value) => { result[value.class] = value },{}
                    ),
                    is: _.transform(
                        [...action.response].filter(vehicle => vehicle.vehicle_location == 'IS'),
                        (result, value) => { result[value.class] = value },{}
                    ),
                    it: _.transform(
                        [...action.response].filter(vehicle => vehicle.vehicle_location == 'IT'),
                        (result, value) => { result[value.class] = value },{}
                    ),
                    pr: _.transform(
                        [...action.response].filter(vehicle => vehicle.vehicle_location == 'PR'),
                        (result, value) => { result[value.class] = value },{}
                    ),
                    us: _.transform(
                        [...action.response].filter(vehicle => vehicle.vehicle_location == 'US'),
                        (result, value) => { result[value.class] = value },{}
                    ),
                }
            }
        case 'getCMSOptions.ok':
            return {
                ...state,
                options: {
                    ...action.response,
                },
            }
        case 'getCMSCovers.ok':
            return {
                ...state,
                covers: _.transform(
                    [...action.response],
                    (result, value) => {
                        ;(result[value.mode] || (result[value.mode] = [])).push(value)
                    },
                    {},
                ),
            }
        case 'getCMSLocales.ok':
            return {
                ...state,
                locales: {
                    ...action.response[0],
                },
            }
        case 'getCMSPartners.ok':
            return {
                ...state,
                partners: {
                    ...action.response,
                },
            }
        case 'getCMSRegions.ok':
            return {
                ...state,
                regions: [...action.response],
            }
        case 'getCMSAges.ok':
            return {
                ...state,
                ages: [...action.response],
            }
        case 'getCMSSwitchboards.ok':
            return {
                ...state,
                switchboards: {
                    ...action.response[0],
                },
            }
        case 'clearPromocode': {
            return {
                ...state,
                promocode: {},
            }
        }
        case 'checkPromocode.ok':
            return {
                ...state,
                promocode: {
                    ...action.response,
                },
            }
        case 'checkRelocation.ok':
            return {
                ...state,
                relocation: {
                    ...action.response,
                },
            }
        case 'clearQuotation':
            return {
                ...state,
                quotation: {
                    trip: 1,
                    split: false,
                    splitDateSelected: 0,
                    splitCreateEntered: false,
                    phase: 'searchresult',
                    ferry: false,
                    optin: false,
                    mode: 'default',
                    relocationID: 0,
                    relocationCars: {},
                    country: '',
                    isAmendment: false,
                    depositAllowed: true,
                    trips: {
                        1: defaultTrip,
                        2: defaultTrip,
                    },
                    customer: {
                        custno: '',
                        firstname: '',
                        lastname: '',
                        email: '',
                        gender: '',
                        street1: '',
                        street2: '',
                        city: '',
                        postalcode: '',
                        statecode: '',
                        countrycode: '',
                        phone: '',
                        birthdate: '',
                        birthplace: '',
                        birthcountry: '',
                        velocityNumber: '',
                        licno: '',
                        licissuer: '',
                        licexp: '',
                        flightno: '',
                        arrival: '',
                        aamembernumber: '',
                        velocityabn: '',
                    },
                    ferryData: {
                        adult: {
                            price: '72.00',
                            details: [],
                        },
                        child: {
                            price: '35.00',
                            details: [],
                        },
                        infant: {
                            price: '0.00',
                            details: [],
                        },
                        departure: {
                            date: 'Select Date',
                            time: '',
                            company: '',
                        },
                    },
                },
            }
        default:
            return state
    }
}
